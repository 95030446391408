import { defineNuxtRouteMiddleware, navigateTo } from '#imports'
import { useAsyncQuery } from '~/composables/useQuery'
import { gqlSelect } from '~/utils/graphql-helpers'

export default defineNuxtRouteMiddleware(async (to) => {
  const businessEntityUuid = to.params.business_entity_uuid as string

  if (!businessEntityUuid) return

  const { data } = await useAsyncQuery('GetBusinessEntity',
    { uuid: businessEntityUuid },
  )

  const businessEntity = gqlSelect(data.value?.businessEntity, 'BusinessEntity')

  if (!businessEntity?.started) {
    return navigateTo(`/business_entities/${businessEntityUuid}/introduction`)
  }
})
